.promotion-container {
  width: 100%;
}

.promotion-content {
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 25px;
  column-gap: 25px;
}

.promotion-header-container {
  grid-area: 1 / 1 / 1 / 3;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 14px;
  margin: 0;
  grid-column-start: 1;
  grid-column-end: 3;
  position: relative;
  overflow: hidden;
}

.promotion-header-container img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.promotion-header-content {
  max-width: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4% 6%;
  flex-direction: column;
  position: relative;
  z-index: 1;
  height: 100%;
  min-height: 12vw;
}

.promotion-header-content span {
  margin: 0;
  line-height: 1.1;
  text-transform: uppercase;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 46px;
}

.promotion-card {
  position: relative;
  overflow: hidden;
  border-radius: 14px;
  margin: 0;
}

.promotion-card img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.promotion-card-container {
  padding: 4% 6%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  height: 100%;
  min-height: 12vw;
}

.promotion-card-title {
  margin: 0 0 15px;
  line-height: 1.1;
  text-transform: uppercase;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  width: 80%;
  font-size: 30px;
}

.promotion-card-content {
  font-size: 18px;
  margin: 0 0 10px;
  width: 80%;
}

.promotion-button-section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.promotion-get-btn {
  font-size: 14px;
  margin: 0 10px 0 0;
  min-width: 120px;
  max-width: 100%;
  padding: 7px 22px;
  background-color: #3e3ef4 !important;
  border: none !important;
  border-radius: 10px;
  box-shadow: none !important;
  color: #fff;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}

.promotion-get-btn:hover {
  color: #fff;
  background: #0e0ef1 !important;
}

.promotion-get-btn:focus {
  color: #fff;
  background: #0e0ef1 !important;
}

.promotion-get-btn:active {
  color: #fff;
  background-color: #0e0ef1 !important;
}

.promotion-info-btn {
  font-size: 14px;
  margin: 0 0 0 10px;
  max-width: 100%;
  padding: 7px 23px;
  min-width: 120px;
  color: #1c1a32;
  background-color: #fff !important;
  border: none;
  border-radius: 10px;
  box-shadow: none !important;
  transition: 0.25s ease-in-out;
}

.promotion-info-btn:hover {
  color: #1c1a32;
  background: #e6e6e6 !important;
}

.promotion-info-btn:focus {
  color: #1c1a32;
  background: #e6e6e6 !important;
}

.promotion-info-btn:active {
  color: #1c1a32 !important;
  background: #e6e6e6 !important;
}

.promotion-footer-container {
  margin-top: 40px;
}

.promotion-footer-title {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 15px;
}

.promotion-footer-content {
  position: relative;
  overflow: hidden;
  border-radius: 14px;
  margin: 0;
  max-height: 281px;
}

.promotion-footer-content img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.promotion-footer-text {
  max-width: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3.9% 6%;
  flex-direction: column;
  position: relative;
  z-index: 1;
  height: 100%;
  min-height: 12vw;
}

.promotion-footer-content-title {
  color: #fff;
  width: 100%;
  margin: 0 0 15px;
  line-height: 1.1;
  text-transform: uppercase;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 30px;
}

.promotion-footer-content-subtitle {
  width: 100%;
  font-size: 18px;
  margin: 0 0 10px;
}

.promotion-footer-btn-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 25px 0 0;
}

.promotion-footer-info-btn {
  min-width: 200px;
  margin-right: 10px;
  color: #fff;
  background-color: #3e3ef4 !important;
  border: none;
  border-radius: 10px;
  padding: 12px 18px;
  font-size: 16px;
  box-shadow: none !important;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}

.promotion-footer-info-btn:hover {
  color: #fff;
  background-color: #0e0ef1 !important;
}

.promotion-footer-info-btn:active {
  color: #fff;
  background-color: #0e0ef1 !important;
}

.promotion-footer-bonus-btn {
  margin-left: 10px;
  font-size: 16px;
  border: none;
  color: #1c1a32;
  background: #fff !important;
  border-radius: 10px;
  padding: 12px 18px;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}

.promotion-footer-bonus-btn:hover {
  color: #1c1a32;
  background: #e6e6e6 !important;
}

.promotion-footer-bonus-btn:focus {
  color: #1c1a32;
  background: #e6e6e6 !important;
}

.promotion-footer-bonus-btn:active {
  color: #1c1a32 !important;
  background: #e6e6e6 !important;
}

.promotion-page-container {
  width: 100%;
}

.promotion-page {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.promotion-page-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 14px;
  margin: 0;
  grid-column-start: 1;
  grid-column-end: 3;
  position: relative;
  overflow: hidden;
}

.promotion-page-header-container img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.promotion-page-header-content {
  padding: 4% 6%;
  flex-direction: row;
  align-items: center;
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
}

.promotion-page-header-text {
  max-width: 45%;
  display: flex;
  flex-direction: column;
}

.promotion-page-header-text .title {
  margin: 0 0 15px;
  font-size: 46px;
  line-height: 1.1;
  text-transform: uppercase;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.promotion-page-header-text .subtitle {
  margin: 0;
  font-size: 18px;
}

.promotion-bonus-card {
  min-width: 250px;
  max-width: 250px;
  max-height: 250px;
  margin: 0 0 0 5%;
  background: rgba(35, 37, 52, 0.6);
  padding: 0 10px;
  border-radius: 6px;
  font-size: 16px;
}

.bonus-card-row {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3b3c5c;
  margin: 0;
  font-weight: 500;
}

.bonus-card-row:last-child {
  border: none;
}

.bonus-name {
  padding: 9px 20px;
  color: #b9b9c1;
  font-size: 18px;
  margin: 0;
  text-transform: capitalize;
  text-align: start;
}

.bonus-value {
  padding: 9px 20px;
  color: #fff;
  font-size: 18px;
  margin: 0;
  text-transform: capitalize;
  text-align: end;
}

.promotion-bonus-info-container {
  color: #fff;
  padding: 25px 20px;
  background: #1b1c26;
  border-radius: 18px;
  color: #b5b5c7;
  line-height: 1.6;
  margin-bottom: 25px;
}

.promotion-bonus-info-content {
  border-bottom: 1px solid #32333c;
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-bottom: 25px;
}

.promotion-bonus-info-content .title {
  font-size: 24px;
  margin: 0 0 15px;
  font-weight: 500;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.promotion-bonus-info-content .text {
  margin-bottom: 25px;
  font-size: 16px;
  padding: 0 20px;
}

.promotion-bonus-info-content .text h1 {
  font-size: 30px;
  margin-bottom: 25px;
  color: #fff;
}

.bonus-terms-button {
  margin-left: 20px;
  color: #fff;
  line-height: 1;
  border-bottom: 1px solid #fff;
  transition: 0.25s all ease;
  font-size: 16px;
  cursor: pointer;
}

.bonus-terms-button:hover {
  color: #fff;
  border-bottom-color: transparent;
}

@media screen and (max-width: 1200px) {
  .promotion-content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .promotion-card-title {
    font-size: calc(16px + 14 * ((100vw - 320px) / 880));
  }

  .promotion-header-content span {
    font-size: calc(20px + 26 * ((100vw - 320px) / 880));
  }

  .promotion-get-btn {
    font-size: calc(10px + 4 * ((100vw - 320px) / 880));
  }

  .promotion-info-btn {
    font-size: calc(10px + 4 * ((100vw - 320px) / 880));
  }

  .promotion-footer-content-title {
    font-size: calc(16px + 14 * ((100vw - 320px) / 880));
  }

  .promotion-footer-content-subtitle {
    font-size: calc(14px + 4 * ((100vw - 320px) / 880));
  }

  .promotion-footer-info-btn {
    font-size: calc(12px + 4 * ((100vw - 320px) / 880));
  }

  .promotion-footer-bonus-btn {
    font-size: calc(12px + 4 * ((100vw - 320px) / 880));
  }

  .promotion-footer-title {
    font-size: calc(16px + 8 * ((100vw - 320px) / 880));
  }

  .promotion-page-header-text .title {
    font-size: calc(22px + 24 * ((100vw - 320px) / 880));
  }

  .promotion-page-header-text .subtitle {
    font-size: calc(14px + 4 * ((100vw - 320px) / 880));
  }

  .bonus-card-row p {
    font-size: calc(14px + 4 * ((100vw - 320px) / 880));
  }

  .promotion-bonus-info-content {
    font-size: calc(16px + 8 * ((100vw - 320px) / 880));
  }

  .bonus-terms-button {
    font-size: 14px;
  }
}

@media screen and (max-width: 990px) {
  .promotion-content {
    display: grid !important;
  }
}

@media screen and (max-width: 768px) {
  .promotion-content {
    display: flex !important;
  }

  .promotion-header-container {
    border-radius: 0;
    min-height: 40vw;
  }

  .promotion-card-container {
    padding: 4% 2%;
  }

  .promotion-card {
    margin: 0 10px;
  }

  .promotion-get-btn {
    min-width: 0 !important;
    border-radius: 6px;
    margin: 0 5px 0 0px;
  }

  .promotion-info-btn {
    min-width: 0 !important;
    border-radius: 6px;
    margin: 0 0 0 8px;
  }

  .promotion-footer-text {
    display: block;
    text-align: start;
    padding: 4% 1%;
  }

  .promotion-footer-btn-section {
    display: block;
  }

  .promotion-footer-info-btn {
    border-radius: 6px;
    margin-right: 8px;
  }

  .promotion-footer-bonus-btn {
    border-radius: 6px;
    margin-left: 8px;
  }

  .promotion-footer-container {
    margin: 40px 10px 0 10px;
  }

  .promotion-page-header-container {
    border-radius: 0 !important;
  }

  .promotion-page-header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3% 1%;
    row-gap: 15px;
  }

  .promotion-page-header-text {
    max-width: none;
  }

  .promotion-page-header-text .title {
    margin: 0 0 5px;
  }

  .promotion-bonus-card {
    margin: 0;
    min-width: 0;
    max-width: 190px;
    width: 100%;
  }

  .promotion-page-header-container:first-child {
    padding: 0 10px;
  }
}

.payment {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
}

.payment-banner {
  min-height: 350px;
  padding: 30px 40px;
  border-radius: 16px;
  background-position: center;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.payment-banner-title {
    color: #fff;
    font-weight: bold;
    font-size: 52px;
}

.payment-banner-subtitle {
    color: #ccc;
    font-size: 16px;
 }

.payment-methods-container {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
}

.payment-method {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
}

 .payment-method-title {
    font-weight: bold;
    font-size: 30px;
 }

.payment-methods-table .ant-table  {
    border-radius: 6px !important;
  background: #1b1c26 !important;
}

.payment-methods-table  th.ant-table-cell {
  font-weight: 500;
  font-size: 16px;
  color: #fff !important;
  background: #1b1c26 !important;
  border-bottom: 1px solid #64666a !important;
}

.payment-methods-table td.ant-table-cell {
  font-weight: 500;
  font-size: 16px;
  color: #88898e;
  background: #1b1c26 !important;
  border-bottom: 1px solid #64666a !important;
}

.payment-image-wrapper {
    padding: 8px;
    width: 130px;
    background: #fff;
    border-radius: 6px;
    text-align: center;
}

.payment-image {
    position: relative;
    max-width: 100px;
    height: 30px;
}

@media screen and (max-width: 1200px) {
    .payment-methods-table td.ant-table-cell {
      padding: 5px !important;
    }

    .payment-image {
      height: 18px;
    }
}

@media screen and (max-width: 992px) {
  .payment-methods-table  th.ant-table-cell {
    font-size: 14px;
  }

.payment-methods-table td.ant-table-cell {
    font-size: 14px;
  }
}


.mobile-payment-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  border: 1px solid #64666a;
}

.mobile-payment-header {
  border-bottom: 1px solid #eee;
  white-space: normal;
  min-height: 1em;
  width: 100%;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.mobile-header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px !important;
  width: 130px;
}

.mobile-payment-header  img {
  position: relative;
  z-index: 1;
  height: 30px;
  max-width: 100px;
}

.mobile-payment-card .card-row {
  font-weight: 500;
  color: #88898e;
  border-bottom: 1px solid #64666a;
  white-space: normal;
  min-height: 1em;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  padding: 8px;
}

.mobile-payment-card .card-row:last-child {
  border-bottom: none;
}

.mobile-payment-card .button-container { 
  display: flex;
  justify-content: center;
  align-items: center;
}
.recovery-page-container {
  padding: 15px 25px 25px 25px;
  background: #1b1c26;
  border-radius: 18px;
  color: #b5b5c7;
  line-height: 1.6;
  margin-bottom: 25px;
}

.recovery-page-title {
  font-size: 42px;
  color: #fff;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 25px;
}

.recovery-input-container {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin: auto;
}

.recovery-input {
  width: 100%;
  background: #272833;
  color: #fff;
  border: none;
  font-family: "Roboto", sans-serif;
  -webkit-appearance: none;
  max-width: 100%;
  width: 100%;
  height: 42px;
  padding: 0 15px;
  border-radius: 6px;
  font-size: 14px;
  transition: 0.4s all ease;
  outline: none !important;
}

.recovery-input:focus {
  border: 1px solid #fff;
}

.recovery-pass-input-container {
  outline: none !important;
}

.border-err {
  border: 1px solid red !important;
}

.border-err:focus-visible {
  border: 1px solid red !important;
}

.border-succes {
  border: 1px solid greenyellow !important;
}

.border-succes:focus-visible {
  border: 1px solid greenyellow !important;
}

.confirm-password-input {
}

@media screen and (max-width: 1200px) {
  .recovery-page-title {
    font-size: calc(24px + 18 * ((100vw - 320px) / 880));
  }
}

@import url(./fonts.css);

html {
  background-color: black;
}

.uppercase {
  text-transform: uppercase !important;
}

body {
  font-size: 14px;
  line-height: 1.52857143;
  color: white !important;
  background: #131521 !important;
  font-family: "Roboto", sans-serif !important;
  margin: 0 auto;
  height: 100%;
  min-height: 100%;
  padding: 0;
  overflow-x: hidden;
  overflow-y: overlay;
}

::-webkit-scrollbar-track {
  background: #e4e4e4;
  margin-bottom: 4px;
  border-radius: 11px;
}

::-webkit-scrollbar-thumb {
  background: #919595;
  border-radius: 0;
  border: 1px solid #919595;
}

::-webkit-scrollbar {
  width: 5px;
}

#root {
  width: 100%;
  height: 100%;
}

.modal-backdrop {
  opacity: 0 !important;
}

.icofont-2x {
  font-size: 1.3em !important;
}

/*modal*/
.w3-container {
  padding: 0;
  position: absolute;
}

.w3-modal {
  display: block;
  z-index: 1990;
  vertical-align: middle;
  min-height: auto;
}

.w3-modal-content {
  max-width: 450px;
  margin: 0 auto;
  border-radius: 10px;
}

.w3-button {
  position: absolute;
  right: px;
  padding: 4px;
  background-color: transparent;
  color: #a9abab;
  border: solid 1px transparent;
  z-index: 2;
  cursor: pointer;
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  transform: translate(0, -6px);
  top: 2px;
  font-size: 23px;
}

.w3-button:hover {
  color: #a9abab !important;
  background-color: transparent !important;
}

.modal-body {
  position: relative;
  padding: 20px 40px;
}

.modal-content {
  /*background-color: #e4e4e4;*/
  border: none;
  background-clip: padding-box;
  outline: 0;
  padding: 0;
  color: #2e302e;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 16px;
  padding-bottom: 15px;
}

.modal-content input,
.modal-content-deposit input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 14px;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  padding: 12px 2px 11px 11px;
  font-family: "Roboto", sans-serif;
  color: #232c2c;
  font-weight: 400;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

.modal-body input:focus {
  outline: none;
}

.modal-body input {
  margin-top: 2px;
  border: 1px solid #4a3e6d !important;
  height: 42px;
  padding: 0 15px;
  border-radius: 6px !important;
}

.buttons {
  margin-bottom: 3px;
  margin: 0;
  position: relative;
}

.center {
  text-align: center;
}

.w60 {
  width: 60%;
}

.margin20 {
  margin-top: 20px;
}

.w3-modal footer {
  padding-bottom: 30px;
}

/* loading */

.loading {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  /*background-color: rgba(0,0,0,1);*/
  background-color: #000;
}

.loading .spinner {
  width: 14em;
  height: 14em;
}

.spinner {
  margin-top: 0;
  display: inline-block;
  width: 4em;
  height: 4em;
  font-size: 0.4rem;
  text-indent: 999em;
  overflow: hidden;
  border: 2px solid #dadada;
  border-top: 2px solid #fd2d5e;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1999;
}

/*error message*/

.validation-summary-errors.error li {
  margin-bottom: 0;
  font-weight: 500;
  padding: 5px 14px 7px 12px;
  font-size: 12px;
}

.validation-summary-errors.error i {
  font-size: 16px;
  margin-right: 2px;
  color: #3797ef;
}

.collapse input:focus {
  outline: none;
}

.tab-pane input {
  outline: none;
}

.account-forms {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  margin-left: -5px;
  margin-right: -5px;
}

.grid-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.account-forms > div {
  padding-left: 5px;
  padding-right: 5px;
}

.grid-cell {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.birthday-form select,
.address-form select {
  color: #8d8d8d;
  cursor: pointer;
  padding: 12px 2px 11px 11px;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #e1e1e1;
  width: 110px;
}

.address-form select {
  width: 170px;
}

.birthday-form select:focus,
.address-form:focus {
  outline: none;
}

/*toggle*/

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3797ef;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3797ef;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*login form*/

.modal-title-padding {
  padding: 5px 0 0;
}

.modal-title-padding p {
  color: black;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 3px;
  padding-top: 10px;
}

.full {
  width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #3797ef;
}

a:hover {
  text-decoration: none;
  color: #3797ef;
}

.full-page {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding-left: 230px;
}

.sidebar-container {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  bottom: 0;
  width: 230px;
  overflow: auto;
  background: #1c1d27;
  padding: 0 15px;
  height: 100%;
}

.page-content {
  width: 100%;
  padding: 0px 20px 0px 20px;
}

.wrapper {
  width: 100%;
  box-sizing: border-box;
  min-height: 580px;
}

.max-width {
  margin: 0 auto;
}

.headerlogo-container {
  -webkit-box-flex: initial;
  -moz-box-flex: initial;
  -webkit-flex: initial;
  -moz-flex: initial;
  -ms-flex: initial;
  flex: initial;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  width: 100%;
  padding: 10px 0;
}

.left {
  padding-left: 10px;
}

.right {
  margin-left: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
}

.navbar-default {
  margin: 17px auto 9px;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}

.logo {
  -webkit-box-flex: initial;
  -moz-box-flex: initial;
  -webkit-flex: initial;
  -moz-flex: initial;
  -ms-flex: initial;
  flex: initial;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: -2px 0 0 0;
  -webkit-box-flex: 0 1 auto;
  -moz-box-flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.header-button-section {
  width: 100%;
  -webkit-box-flex: initial;
  -moz-box-flex: initial;
  -webkit-flex: initial;
  -moz-flex: initial;
  -ms-flex: initial;
  flex: initial;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  margin-left: auto;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  column-gap: 11px;
}

input {
  width: 65%;
  border-radius: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.validation-summary-errors.alert {
  margin: 0.9em 0 0 0;
  padding: 0;
}

.alert {
  width: 100%;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #94a4a6;
  padding: 9px 8px 9px;
  margin-top: 0.9em;
}

.relative {
  position: relative;
}

.table-cell {
  padding-left: 6px;
}

/**/

.close-icon {
  position: absolute;
  right: 6px;
  top: 11px;
  cursor: pointer;
}

/**/

.field-validation-error {
  position: relative;
  font-size: 14px;
  color: #f33535;
  white-space: nowrap;
  font-weight: 400;
  letter-spacing: 0.03em;
  padding-top: 3px;
  z-index: 1000;
  font-family: "Open Sans", sans-serif;
}

/*user logged*/

.username {
  display: flex !important;
}

ul.nav {
  padding: 0;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  display: inline-flex;
  width: 100%;
  margin: -1px 10px 7px;
  margin-bottom: 9px;
}

ul.nav > li {
  display: table-cell;
  text-align: center;
  position: relative;
  height: 100%;
  line-height: 18px;
  vertical-align: middle;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

ul.nav > li.active {
  background-color: #000;
}

ul.nav > li > a {
  color: #fff;
  display: table;
  padding: 11px 14px 7px;
  -moz-flex-direction: column;
  flex-direction: column;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  margin: 0;
  height: 100%;
  position: relative;
  text-align: center;
}

ul.nav > li > a {
  display: table;
}

ul.nav > li.active > a,
ul.nav > li.active .icon {
  color: #fff;
}

ul.nav > li:hover > a,
ul.nav > li:hover .icon {
  color: #fff;
}

.nav-left {
  margin-left: auto;
  margin-right: 20px;
}

ul.nav .icon {
  color: #bfbfbf;
  font-size: 27px;
  position: relative;
  top: 2px;
  padding-right: 5px;
}

ul.nav .icon {
  display: table-cell;
  vertical-align: middle;
  top: 0;
  font-size: 17px;
}

ul.nav > li.active > a,
ul.nav > li.active .icon {
  color: #fff;
}

ul.nav > li:hover {
  background-color: #000;
  transition-duration: 0.2s;
}

/*content-wrapper*/

.content-wrapper {
  position: relative;
  width: 100%;
}

.content-carusel {
  position: relative;
  display: flex;
  height: 380px;
}

.auto {
  width: auto;
}

/*underline border effect */

.pull-right {
  float: right;
}

.action {
  text-align: right;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-positive: 1;
  -ms-flex-negative: 1;
  -ms-flex-preferred-size: auto;
  padding: 0 0.3em 0 0;
}

.search {
  width: 100%;
  position: relative;
  display: inline-flex;
  padding: 19px 5px 15px;
}

.casino-games-filters > a,
.casino-games-filters > span {
  font-size: 0.9em;
  padding: 0 0.5%;
  font-weight: normal;
}

.grid {
  right: 0;
  /*left:-3px;*/
  left: -2px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  position: relative;
  display: grid;
}

.game-list {
  width: 100%;
  z-index: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 300px;
  min-height: 150px;
  position: relative;
}

.grid-wrap-row {
  column-gap: 12px;
  row-gap: 12px;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  grid-template-columns: repeat(6, 1fr);
}

.w100 {
  width: 100%;
}

.casino .image img {
  width: 100%;
  height: 100%;
}

.btn.load-more-btn {
  background: #1f202b;
  border: 1px solid #242631;
  min-width: 120px;
  max-width: 100%;
  padding: 10px 20px;
  color: #c6c6c6;
  border-radius: 10px;
  margin: 30px auto;
  display: block;
  font-size: 14px;
  box-shadow: none;
}

.load-more-btn:hover {
  color: #c6c6c6;
  background: #242631;
}

.load-more-btn:focus {
  color: #c6c6c6 !important;
  background: #242631 !important;
  border: 1px solid #242631 !important;
  box-shadow: none !important;
}

.game-info-container {
  margin-top: 70px;
}

.questions-container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-top: 20px;
}

.question-title {
  font-size: 30px;
}

.question-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.game-info-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

/*payment logo*/

.payment-deposit-logos {
  display: flex;
  overflow: hidden;
  position: relative;
  border-top: 1px solid #1f212c;
  width: inherit;
}

.payment-deposit-logos .provider-logo {
  margin: 25px 0 0;
  position: relative;
  width: auto;
}

.payment-deposit-logos .game-provider-logo .provider-logo {
  margin: 25px 10px 0;
  position: relative;
  width: auto;
  -webkit-transition: opacity 0.7s ease;
  -o-transition: opacity 0.7s ease;
  transition: opacity 0.7s ease;
}

.payment-deposit-logos .img {
  max-height: 100px;
  max-width: 150px;
}

.img-color {
  opacity: 0 !important;
  position: absolute;
  left: 0;
  top: 0;
  transition-duration: 0.2s;
}

.img-color:hover {
  opacity: 1 !important;
  transition-duration: 0.2s;
}

@media screen and (max-width: 1024px) and (min-width: 991px) {
  .table {
    border-top: none;
  }
}

@media screen and (max-width: 335px) {
  .birthday-form select {
    width: 62px;
  }
}

.navbar-default {
  margin: 0 auto;
  padding: 4px 0;
}

@media screen and (max-width: 767px) {
  .col-e-xs-6 {
    width: 50%;
  }
  .col-e-xs-12 {
    width: 100%;
  }
  .col-e-sm-25 {
    width: 25%;
  }
  .col-e-sm-75 {
    width: 75%;
  }
  .col-e-xs-25 {
    width: 16.6%;
  }
  .col-e-xs-75 {
    width: 83.3%;
  }
}

.red-button {
  background-color: #3797ef;
  border-color: #3797ef;
}

.height-41 {
  height: 41px;
}

/* deposit payment */

.modal-deposit,
.error-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1990;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-dialog {
  width: 100%;
  max-width: 700px;
  margin: 30px auto;
  min-height: 480px;
  pointer-events: all;
}

.game-modal-header {
  padding: 10px;
}

.game-modal-header-title {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.game-sidebar-btns-container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  color: #fff !important;
}

.game-control-btns {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.game-modal-control-btn {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 42px;
  height: 34px;
  font-size: 18px;
  color: #fff;
  background: #1d1e21;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: 0.25s all ease;
  font-family: "icon-woo" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.modal-content-deposit {
  background-color: #fff;
  border: none;
  background-clip: padding-box;
  outline: 0;
  padding: 0;
  color: #2e302e;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  min-height: 400px;
}

.modal-header-deposit {
  background-color: #44464c;
  position: relative;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.close:focus {
  outline: none;
}

.modal-title-deposit {
  padding: 0;
  position: relative;
  font-weight: 400;
  margin: 0 36px 0 15px;
  color: #fff;
  text-shadow: none;
  font-size: 22px;
  text-align: center;
}

.modal-body-deposit {
  padding: 0;
  background-color: inherit;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  overflow-x: hidden;
  margin-top: 10px;
}

.payment-details-section {
  padding: 0 30px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 0;
  border-color: #c7c7c7;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}

.modal-body-deposit section {
  padding: 12px 6px;
}

@media (min-width: 500px) {
  .balance-summary .account-ballance {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
  }
}

.balance-summary .item {
  -webkit-box-flex: 0 1 0;
  -moz-box-flex: 0 1 0;
  -webkit-flex: 0 1 0;
  -moz-flex: 0 1 0;
  -ms-flex: 0 1 0;
  flex: 0 1 0;
  padding: 0 7px;
  white-space: nowrap;
}

.balance-summary .title {
  text-transform: none;
  font-size: 13px;
  color: #7b7b7b;
  padding-bottom: 0;
  margin-bottom: 3px;
  display: block;
  font-weight: 500;
}

.balance-summary .icon {
  color: #7b7b7b;
  font-size: 28px;
}

.balance-summary .money {
  color: #3797ef;
  display: inline-block;
  padding-left: 6px;
  font-size: 18px;
  font-weight: 400;
}

.tab-content.current {
  display: block;
  height: 90%;
}

.modal-body .buttons {
  margin-bottom: 3px;
  margin: 0;
  position: relative;
  margin-top: 18px;
}

.modal-body-deposit .buttons {
  margin-bottom: 3px;
  margin: 0;
  position: relative;
}

.padding-20 {
  padding-left: 20%;
  padding-right: 20%;
}

.modal-content-deposit input:focus {
  outline: none;
}

.btn:focus {
  box-shadow: none;
}

/*error modal*/

.modal-dialog-error {
  max-width: 450px;
}

.modal-header-error {
  background-color: white;
}

.error-modal-title {
  color: black;
}

.modal-header-error .close {
  color: #a9abab;
  font-weight: bold;
}

.modal-error-body {
  padding: 30px;
}

.close-error {
  font-size: 6.3em;
  color: #3797ef;
}

.success {
  font-size: 6.3em;
  color: #00ff00;
}

.modal-content-error {
  min-height: 210px;
}

/*game modal*/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1990;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-game {
  height: calc(100% - 207px);
  margin: 4% 4%;
}

.game-modal-ceontent {
  height: 100%;
  background: #1d1e21;
  border-radius: 10px 0 0 10px;
  margin: 0 !important;
  max-width: 1225px !important;
  padding: 0px 10px 10px 10px !important;
}

/* iframe */

.iframe {
  padding: 35.25% 0 0 0;
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
}

.iframe-game {
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
  height: 95%;
}

.loading-game {
  width: 10em;
  height: 10em;
}

.modal-header-deposit .dark-modal-button {
  color: black !important;
  font-size: 25px !important;
}

/*transaction */
.balance-summary {
  position: relative;
}

.user-area-container {
  padding: 22px 39px 22px 39px;
  background-color: #f6f6f6;
  border-top: solid 1px #e1e1e1;
}

.cards {
  margin-left: -6px;
  margin-right: -6px;
}

.card .dropdown:focus {
  outline: none;
}

.cards .card {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 0 5px;
  position: relative;
  color: #fff;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  margin-left: -6px;
  margin-right: -6px;
}

.card select {
  color: #232c2c;
  cursor: pointer;
  padding: 12px 2px 11px 11px;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #e1e1e1;
  width: 100%;
}

.area-transaction {
  min-height: 350px;
}

.no-data-found-title {
  width: 100%;
  color: #8d8d8d;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding: 15px 50px;
  transform: translateY(50%);
}

.no-data-found-title .icon {
  font-size: 116px;
  color: #bcbcbc;
  display: block;
  padding-bottom: 17px;
}

/*footer-carousel*/
.owl-carousel-footer .provider-logo {
  margin: 5px 0;
  min-width: 150px;
}

/*input info */

.input-info {
  position: relative;
  right: 0;
  left: 100%;
  z-index: 4;
  margin-top: -23px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.input-info .arrow {
  z-index: 1001;
  position: relative;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid transparent;
}

.input-info .arrow:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 0;
  display: block;
  border-bottom: 11px solid transparent;
  border-right: 11px solid #f1f1f1;
  border-top: 11px solid transparent;
  z-index: 1;
}

.input-info .info-container {
  padding: 0.6em;
  border: 1px solid #c8c8c8;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #f1f1f1;
  min-width: 251px;
  max-width: 345px;
  top: -2.5em;
  left: 0.8em;
  z-index: 1000;
  position: absolute;
}

.input-info .info-container .info {
  display: block;
  background-color: #fff;
  padding: 0.5em 0 0.5em 0.5em;
}

.input-info .info-container .info span.title {
  color: #2e302e;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

.input-info .info-container .info span {
  display: table;
  color: #2e302e;
  margin: 4px;
  font-size: 12px;
}

.validation-error {
  color: #3797ef;
  font-size: 15px;
}

.valid {
  color: #518335;
}

@media (max-width: 768px) {
  .input-info .info-container {
    right: 32.1em;
    left: auto;
    border: none;
    background: transparent;
    top: 35px;
  }

  .input-info .arrow {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid white;
    top: 0;
    right: 80%;
    top: 14px;
    right: 72%;
  }

  .input-info .info-container .info {
    border: solid 1px #c8c8c8;
  }

  .input-info .arrow:after {
    display: none;
  }
}

/*my account */

.account-details {
  border-top: solid 1px #e1e1e1;
}

.account-details h4 {
  font-size: 16px;
  letter-spacing: -0.1px;
  color: #212c2c;
  font-weight: 600;
  padding: 0;
  position: relative;
}

.account-details .message {
  color: #555;
  padding-bottom: 11px;
}

.cards.form-margin {
  margin-left: -10px;
  margin-right: -10px;
}

.account-details label {
  color: #bdbdbd;
}

[type="text"].disabled,
[type="password"].disabled,
[type="date"].disabled,
[type="datetime"].disabled,
[type="datetime-local"].disabled,
[type="month"].disabled,
[type="week"].disabled,
[type="email"].disabled,
[type="number"].disabled,
[type="search"].disabled,
[type="tel"].disabled,
[type="time"].disabled,
[type="url"].disabled,
[type="color"].disabled,
textarea.disabled,
select.disabled,
[type="text"]:disabled,
[type="password"]:disabled,
[type="date"]:disabled,
[type="datetime"]:disabled,
[type="datetime-local"]:disabled,
[type="month"]:disabled,
[type="week"]:disabled,
[type="email"]:disabled,
[type="number"]:disabled,
[type="search"]:disabled,
[type="tel"]:disabled,
[type="time"]:disabled,
[type="url"]:disabled,
[type="color"]:disabled,
textarea:disabled,
select:disabled {
  background-color: #f6f6f6;
  border-color: #d2d2d2;
  color: #a0a0a0;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

/*messages*/

.promotion ol,
.promotion ul {
  list-style: revert;
}

.btn.terms {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.btn.terms:hover {
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  color: #3797ef;
}

@media screen and (max-width: 1024px) {
  .header-container.active input {
    opacity: 1;
    height: 36px;
    background-color: rgb(255, 255, 255);
    border-color: rgb(225, 225, 225);
  }
}

/*Betting Rules*/

hr {
  border-top: 1px solid #eee;
}

.empty-game-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  line-height: 3;
  text-align: center;
  color: #fff;
  padding: 150px;
}

.search-img {
  max-width: 48px;
  height: auto;
  border: 0;
}

.game-not-found {
  white-space: nowrap;
}

.spinner-loading {
  margin: 75px auto;
  padding: 35px;
}

.clearable {
  position: relative;
  display: inline-block;
}

.clearable input[type="text"] {
  padding-right: 24px;
  width: 100%;
  box-sizing: border-box;
}

.clearable__clear {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 8px;
  font-style: normal;
  font-size: 1.4em;
  user-select: none;
  cursor: pointer;
}

.clearable input::-ms-clear {
  display: none;
}

.primarycheck {
  font-size: 6.3em;
  color: #3797ef;
}

.input-validation-error {
  border-color: #d96467 !important;
  outline-color: #d96467 !important;
  outline-offset: 0;
}

.input-validation-error:focus {
  border-color: #d96467 !important;
  outline-color: #d96467 !important;
  outline-offset: 0;
}

.input-validation-error:focus-visible {
  border-color: #d96467 !important;
  outline-color: #d96467 !important;
  outline-offset: 0;
}

input.input-validation-error:focus {
  outline: none;
  border: 1px solid #d96467;
  -webkit-box-shadow: 0px 0px 5px #d96467;
  box-shadow: 0px 0px 5px #d96467;
}

.formvalid:focus {
  outline: none;
  border: 1px solid #3797ef;
  -webkit-box-shadow: 0px 0px 5px #3797ef;
  box-shadow: 0px 0px 5px #3797ef;
}

.select-validation-error {
  font-size: 12px;
  color: #f33535;
  font-style: italic;
  white-space: nowrap;
  font-weight: 400;
  letter-spacing: 0.03em;
  font-style: normal;
}

.modal-header {
  padding: 22px 0;
  position: relative;
  background-color: #342d56;
  border-bottom: solid 1px transparent;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 7px 7px 0 0;
}

@media screen and (max-width: 1600px) {
  .grid-wrap-row {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .game-info-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .question-title {
    font-size: calc(18px + 12 * ((100vw - 320px) / 880));
  }

  .btn.load-more-btn {
    font-size: calc(10px + 4 * ((100vw - 320px) / 880));
  }
}

@media screen and (max-width: 990px) {
  .full-page {
    padding: 0;
  }

  .header-button-section {
    margin-right: 10px;
  }

  .page-content {
    padding: 0;
    width: 100%;
  }

  .sidebar-container {
    padding: 0;
  }

  .wrapper {
    padding: 0 14px 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 0;
  }

  .game-list {
    padding: 0 10px;
  }

  .grid-wrap-row {
    grid-template-columns: repeat(2, 1fr);
  }

  .game-info-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .header-button-section {
    column-gap: 10px;
  }
}

@media screen and (max-width: 480px) {
  .header-button-section {
    margin-right: 0;
  }
}

.bonus-terms-modal {
  margin: 50px auto !important;
  max-width: 800px !important;
}

.modal-close-btn {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 24px;
  background: transparent;
  border: none;
  outline: none;
  z-index: 1;
  transition: 0.25s all ease;
  color: #fff;
  cursor: pointer;
}

.bonus-terms-modal-title {
  display: flex;
  justify-content: center;
  font-size: 42px;
  margin: 0 0 25px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  white-space: nowrap;
}

.bonus-terms-modal .modal-content {
  background: #131521;
  color: #fff;
  border-radius: 0 !important;
  padding: 15px 0;
}

.bonus-terms-body {
  padding: 25px 0 !important;
}

.bouns-terms-content {
  overflow: auto;
  max-height: calc(90vh - 100px);
  padding: 0 30px;
}

.bonus-terms-container {
  background: #1b1c26;
  border-radius: 18px;
  color: #b5b5c7;
  line-height: 1.6;
  margin-bottom: 25px;
  row-gap: 10px;
  padding: 25px 20px;
}

.bonus-terms-container .title {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}

.bonus-terms-container p {
  font-size: 16px;
}

.bouns-terms-content::-webkit-scrollbar-track {
  background: transparent;
  margin-bottom: 4px;
  border-radius: 11px;
}

.bouns-terms-content::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.7);
  border-radius: 20px;
  border: none;
}

.bouns-terms-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

@media screen and (max-width: 1200px) {
  .bonus-terms-modal-title {
    font-size: calc(24px + 18 * ((100vw - 320px) / 880));
  }

  .bonus-terms-container .title {
    font-size: calc(18px + 6 * ((100vw - 320px) / 880));
  }

  .bonus-terms-container p {
    font-size: 14px;
  }
}

.modal-body {
  padding: 20px 40px 40px;
}

.login-modal-body {
  display: flex;
  flex-direction: column;
}

.forgot-password {
  padding: 3px 0 20px;
}

.forgot-password-link {
  color: #4a3e6d !important;
  cursor: pointer !important;
  text-decoration: underline;
}

.login-btn {
  background: #3e3ef4;
  color: #fff;
  border-radius: 10px;
  width: 380px;
  height: 49px;
}

.registr-btn {
  margin-top: 15px;
  color: #1c1a32;
  border: 1px solid #ee3661;
  background-color: #fff;
  border-radius: 10px;
  width: 380px;
  height: 49px;
}

.forgot-password-link:hover {
  text-decoration: none !important;
}

.login-btn:hover {
  background: #0e0ef1;
  color: #fff;
}

.registr-btn:hover {
  background: #e6e6e6;
}

@media screen and (max-width: 1200px) {
  .login-btn {
    font-size: calc(14px + 4 * ((100vw - 320px) / 880));
  }

  .registr-btn {
    font-size: calc(14px + 4 * ((100vw - 320px) / 880));
  }
}

@media screen and (max-width: 768px) {
  .w3-modal-content {
    max-width: none;
    height: 100%;
    border-radius: 0;
  }

  .forgot-password {
    font-size: 12px;
  }

  .login-btn {
    width: 100%;
    border-radius: 5px;
    height: 46px;
  }

  .registr-btn {
    width: 100%;
    border-radius: 5px;
    height: 46px;
  }
}

.register-container,
.register-left-container {
  min-height: 605px;
}

.register-left-container div {
  background: no-repeat center bottom/100% 100%;
  box-shadow: #1a2131;
  min-height: 350px;
}

.register-container.w3-modal-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.register-left-container {
  background: #000;
}

.register-left-container .header-text {
  text-align: center;
  font-size: 60px;
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  min-height: 350px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 0;
}

.register-left-container .header-text-title {
  display: block;
  color: #fff;
  font-size: 24px;
}

.register-left-container .header-text-subtitle {
  color: #ffe400;
  font-size: 70px;
  letter-spacing: -0.05em;
  line-height: 1;
  display: block;
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.register-left-container .header-text .spins {
  display: block;
  color: #fff;
  font-size: 24px;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.register-left-container ul {
  display: block;
  background: transparent;
  margin: 10px 40px 0 40px;
  padding: 20px 0 0 40px;
  border-top: 1px solid #737373;
  color: #fff;
}

.register-left-container ul li {
  padding: 0 0 25px 40px;
  font-weight: 600;
  position: relative;
  color: #90879e;
}

.register-left-container ul li.active {
  color: #fff;
}

.register-left-container ul li.done {
  color: #17c167;
}

.register-left-container ul li.active:before {
  color: #ebb409;
  background: #ffe400 !important;
  border-color: #ffc700 !important;
  box-shadow: 0 0 11px #ffe400;
}

.register-left-container ul li.done:before {
  color: #17c167;
  background: #17c167 !important;
  border-color: #17c167 !important;
  box-shadow: 0 0 11px #17c167;
}

.register-left-container ul li:before {
  position: absolute;
  content: "";
  border-radius: 50%;
  font-size: 18px;
  border: 2px solid #fff;
  top: 3px;
  left: -3px;
  width: 12px;
  height: 12px;
  background: #151746;
  z-index: 100;
}

.register-left-container ul li:not(:last-child):after {
  width: 3px;
  top: 6px;
  left: 2px;
  bottom: -6px;
  background: #fff;
  content: "";
  position: absolute;
}

.register-left-container .last-step:before {
  left: -22px;
  top: -6px;
  background: #151746 url(/img/gift.png) no-repeat center 6px/30px;
  width: 50px;
  height: 50px;
  content: "";
  position: absolute;
  border-radius: 50%;
  font-size: 18px;
  color: #000;
  border: 2px solid #fff;
  z-index: 100;
}

.register-left-container .last-step-header {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.1;
  display: block;
}

.register-left-container .last-step-spins {
  color: #ffe400;
  font-size: 14px;
  text-shadow: 0 0 15px #ffe400;
  display: block;
  text-transform: uppercase;
}

.register-left-container .last-step-welcome {
  color: #ffe400;
  font-size: 14px;
  display: block;
}

.right-container {
  border-radius: 0 10px 10px 0;
}

.right-container .register-title {
  margin: 70px 0 0;
  color: #342d56;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
}

.right-container .ant-select {
  width: 100%;
  max-width: 100%;
  outline: none;
  line-height: 42px;
  height: 42px;
  text-align: left;
  background: #fff;
  border: 1px solid #4a3e6d;
  border-radius: 6px;
  color: #4a3e6d;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-container .ant-select-selector {
  min-height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
  border: none !important;
}

.right-container .ant-select-selector:focus {
  border: none !important;
}

.right-container .ant-select-focused .ant-select-selector {
  background: #e8f0ff !important;
}

.ant-select-item {
  height: 42px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #b69aa5;
  background: #fffbfd !important;
}

.ant-select-item:hover {
  background: #e8f0ff !important;
}

.right-container .accept-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 10px;
  margin-top: 10px;
  font-size: 14px;
  transition: 0.25s all ease;
}

.right-container .accept-container span {
  color: #4a3e6d !important;
}

.accept-container a {
  padding: 0 5px;
  color: #4a3e6d;
  line-height: 1;
  border-bottom: 1px solid #4a3e6d;
  transition: 0.25s all ease;
  font-weight: 700;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4a3e6d;
  border-color: #4a3e6d !important;
}

.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-checked:hover .ant-checkbox-inner {
  border-color: #4a3e6d !important;
}

.continue-button {
  font-size: 14px;
  background: #fd2d5e;
  width: 130px;
  border: 1px solid #ff4e77;
  color: #fff !important;
  box-shadow: 0 3px 0 #960326;
  border-radius: 10px;
  text-shadow: 0 1px 0 rgb(0 0 0 / 45%);
  margin-top: 25px;
  margin-left: auto;
}

.continue-button:hover {
  background: #ff4e77;
}

.are-you-register {
  color: #000;
  display: flex;
  column-gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.are-you-register span {
  color: #fd2d5e;
  cursor: pointer;
  font-size: 16px;
}

.ask-validation {
  font-size: 12px;
  color: #f33535;
  margin-top: 5px;
}

.modal-content {
  padding-bottom: 0;
}

.register-container .w3-display-topright {
  right: -35px;
  top: -35px;
  font-size: 27px;
  color: #fff;
}

.back-button {
  font-size: 14px;
  background: #3e3ef4 !important;
  border: 1px solid #2424af !important;
  box-shadow: 0 3px 0 #2424af !important;
  border-radius: 10px;
  text-shadow: 0 1px 0 rgb(0 0 0 / 45%);
  margin-left: 0;
}

.back-button:hover {
  background: #7777ff !important;
  border: 1px solid #7474ff !important;
}

.register-container .modal-body {
  height: 100%;
}

.w3-modal-content.register-container {
  max-width: 760px !important;
}

.register-container .modal-body {
  padding: 20px 35px !important;
}

.register-container .modal-body .buttons {
  display: flex;
  justify-content: space-between;
}

.password-strength-bar {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-top: 5px;
  padding: 0 20px;
}

.ant-select-dropdown {
  z-index: 2000;
  border: 1px solid #b69aa5;
  border-radius: 0 0 10px 10px;
}

.password-strength-bar span {
  padding-right: 20px;
  color: #e06262;
  font-size: 9px;
}

.password-strength-bar .perfect {
  color: #8fc118;
}

.password-strength-bar .strong {
  color: #1db7e7;
}

.password-strength-bar .weak {
  color: #fbd01f;
}

.password-strength-bar .awful {
  color: #e06262;
}

.PhoneInput {
  position: relative;
}

.PhoneInputCountry {
  position: absolute;
  height: 100%;
  left: 20px;
}

.phone-container {
  column-gap: 10px;
}

.PhoneInput .PhoneInputInput {
  padding-left: 50px !important;
  max-width: 111px;
}

.PhoneInputCountrySelectArrow {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 1200px) {
  .register-left-container .header-text-subtitle {
    font-size: calc(30px + 40 * ((100vw - 320px) / 880));
  }

  .continue-button {
    font-size: calc(10px + 4 * ((100vw - 320px) / 880));
  }

  .back-button {
    font-size: calc(10px + 4 * ((100vw - 320px) / 880));
  }
}

@media screen and (max-width: 768px) {
  .register-container.w3-modal-content {
    display: flex;
    height: auto;
    flex-direction: column;
  }

  .register-left-container {
    min-height: 0;
  }

  .register-container .w3-display-topright {
    right: 10px;
    top: -232px;
    font-size: 20px;
  }

  .register-left-container .header-text {
    padding: 0 0 25px 0;
  }

  .register-left-container div {
    background: linear-gradient(to top, #101031, #302a52);
    min-height: 0 !important;
  }

  .mobile-registration-modal-fs {
    border: 1px solid #fff;
    border-radius: 5px;
    margin-left: 10px;
    padding: 5px;
    font-size: 18px;
    background: rgba(0, 0, 0, 0.4);
  }

  .register-left-container ul {
    display: flex;
    border: none;
    margin: 0;
    background: #fff;
    height: 50px;
    padding-top: 10px;
    padding: 10px calc(100% - 95%) 0 calc(100% - 90%);
  }

  .register-left-container ul li {
    padding-left: calc(100% / 3.7);
  }

  .register-left-container ul li:last-child {
    padding: 0;
  }

  .register-left-container ul li:before {
    position: absolute;
    content: "";
    border-radius: 50%;
    font-size: 18px;
    border: 2px solid #342d56;
    top: 3px;
    left: -3px;
    width: 18px;
    height: 18px;
    background: #151746;
    z-index: 100;
  }

  .register-left-container ul li:after {
    width: 100% !important;
    height: 3px !important;
    top: 10px !important;
    left: 7px !important;
    background: #342d56 !important;
    content: "" !important;
    position: absolute !important;
  }

  .register-left-container ul li:last-child::after {
    display: none;
  }

  .register-left-container ul li:last-child::before {
    display: none;
  }

  .register-left-container .last-step:before {
    left: 0px;
    top: -15px;
    background: url(/img/gift.png) no-repeat center 6px/30px;
    width: 50px;
    height: 50px;
    content: "";
    position: absolute;
    border-radius: 0;
    font-size: 18px;
    color: #000;
    z-index: 100;
    border: none;
  }

  .register-left-container .last-step-header {
    display: none;
  }

  .register-left-container .last-step-spins {
    color: #342d56;
    font-size: 12px;
    position: absolute;
    width: max-content;
    bottom: -5px;
    right: calc(50% - 70px);
    z-index: 1;
    text-shadow: none;
  }

  .register-left-container .last-step-welcome {
    display: none;
  }

  .mobile-register-modal-title {
    background: #fff !important;
    text-align: center;
    color: #342d56;
    font-size: 20px;
    font-weight: 700;
    padding-top: 10px;
    text-transform: uppercase;
  }

  .continue-button {
    min-width: 40%;
    height: 35px;
  }

  .back-button {
    min-width: 40%;
    height: 35px;
  }

  .w3-modal footer {
    padding: 0;
  }

  .are-you-register {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    background: #342d56;
    color: #fff;
    font-size: 14px;
  }

  .phone-container div:last-child {
    width: 100%;
  }
}

@media screen and (max-width: 680px) {
  .register-left-container ul li:after {
    width: 100% !important;
    height: 3px !important;
    top: 10px !important;
    left: 7px !important;
    background: #342d56 !important;
    content: "" !important;
    position: absolute !important;
  }
}

@media screen and (max-width: 480px) {
  .register-container {
    overflow-y: auto;
  }
}
